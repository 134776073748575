<template>
    <v-card>
        <vlab-base-card-title :title="$t('salespecial.form')" :dismissible="!loading" @close="$emit('close')" />
        <v-tabs v-model="tab" centered text background-color="grey lighten-5">
            <v-tab :href="`#form`">
                {{ $t('department.formTab') }}
            </v-tab>
            <v-tab v-if="special && special.id" :href="`#lots`">
                {{ $t('department.highlightTab') }}
            </v-tab>
            <v-tab v-if="special && special.id" :href="`#seo`">
                {{ $t('seo.menu') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-if="special" v-model="tab">
            <v-tab-item value="form">
                <v-card>
                    <v-form ref="form" v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
                        <v-card-text v-if="special">
                            <hdv-base-i18n-area :loading="loading">
                                <template #default="{ ln, isDefault }">
                                    <hdv-sale-special-title
                                        v-model="special.title_i18n[ln]"
                                        :required="isDefault"
                                        :name="`title_i18n-${ln}`" />

                                    <hdv-base-slug-field
                                        v-model="special.slug_i18n[ln]"
                                        :auto-fill="special.title_i18n[ln]"
                                        :required="isDefault"
                                        :name="`slug_i18n-${ln}`" />

                                    <hdv-sale-special-description v-model="special.description_i18n[ln]" :id="specialId" />
                                </template>
                            </hdv-base-i18n-area>

                            <template v-if="sale">
                                <vlab-base-switch v-model="special.is_house" :label="$t('sale.ishouse')" />

                                <hdv-base-image
                                    ref="upload"
                                    :label="$t('salespecial.image')"
                                    :auto-start="!!special.id"
                                    :preview.sync="special.image_url_preview"
                                    :src.sync="special.image_url"
                                    :url="`/sales/${this.saleId}/specials/${this.specialId}/image`"
                                    @loaded="v => loading = v"
                                    dense />

                                <hdv-base-image
                                    ref="uploadBanner"
                                    class="mb-0"
                                    :label="$t('salecatalog.uploadBanner')"
                                    :auto-start="!!special.id"
                                    :preview.sync="special.banner_url_preview"
                                    :src.sync="special.banner_url"
                                    :url="`/sales/${this.saleId}/specials/${this.specialId}/banner`"
                                    @loaded="v => loading = v" />

                                <hdv-base-image
                                    ref="uploadCover"
                                    class="mb-0"
                                    :label="$t('salecatalog.uploadcover')"
                                    :auto-start="!!special.id"
                                    :preview.sync="special.cover_url_preview"
                                    :src.sync="special.cover_url"
                                    :url="`/sales/${this.saleId}/specials/${this.specialId}/cover`"
                                    @loaded="v => loading = v" />

                                <hdv-sale-special-upload
                                    ref="uploadCatalog"
                                    :sale-id="saleId"
                                    :special-id="special.id"
                                    v-model="special.catalog_url"
                                    pdf
                                    inline
                                    type="catalog"
                                    @loaded="v => loading = v"
                                    class="mb-3">
                                    <template #append>
                                        <vlab-base-message-btn
                                            v-if="special.catalog_url"
                                            :message="$t('salespecial.removeCatalog')"
                                            :message-title="$t('salespecial.removeCatalogTitle')"
                                            :message-btn="$t('base.remove')"
                                            :loading="loading"
                                            @submit="removeDoc('catalog')"
                                            color="red"
                                            icon />
                                        <v-btn color="red" icon target="_blank" :href="special.catalog_url" :disabled="!special.catalog_url">
                                            <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                                        </v-btn>
                                    </template>
                                </hdv-sale-special-upload>

                                <hdv-sale-special-upload
                                    ref="uploadResult"
                                    :sale-id="saleId"
                                    :special-id="special.id"
                                    v-model="special.results_url"
                                    pdf
                                    inline
                                    type="results"
                                    @loaded="v => loading = v">
                                    <template #append>
                                        <vlab-base-message-btn
                                            v-if="special.results_url"
                                            :message="$t('salespecial.removeResults')"
                                            :message-title="$t('salespecial.removeResultsTitle')"
                                            :message-btn="$t('base.remove')"
                                            :loading="loading"
                                            @submit="removeDoc('results')"
                                            color="red"
                                            icon />
                                        <v-btn color="red" icon target="_blank" :href="special.results_url" :disabled="!special.results_url">
                                            <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                                        </v-btn>
                                    </template>
                                </hdv-sale-special-upload>

                                <hdv-sale-special-issuu class="mt-4" v-model="special.issuu_url" />

                                <v-row>
                                    <v-col>
                                        <hdv-sale-special-category
                                            :label="$t('salespecial.categoryStart')"
                                            :items="categories"
                                            v-model="special.category_start" />
                                    </v-col>
                                    <v-col>
                                        <hdv-sale-special-category
                                            :label="$t('salespecial.categoryEnd')"
                                            :items="categories"
                                            v-model="special.category_end" />
                                    </v-col>
                                </v-row>

                                <hdv-sale-special-categories v-model="special.categories" :items="categories" />
                                <hdv-sale-special-online v-model="special.online" />
                                <hdv-sale-special-story
                                    :items="stories"
                                    v-model="special.story_id" />

                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                rounded
                                color="primary"
                                type="submit"
                                :disabled="!valid"
                                :loading="loading">
                                {{ $t('base.save') }}
                            </v-btn>
                            <v-spacer />
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="special.id" value="lots">
                <hdv-sale-special-highlight
                    :sale-id="saleId"
                    :special-id="special.id"
                    :lots="special.highlights" />
            </v-tab-item>
            <v-tab-item v-if="special.id" value="seo">
                <v-card>
                    <v-form v-model="validSeo" @submit.stop.prevent="submitSeo" ref="formSeo">
                        <v-card-text>
                            <hdv-seo-form
                                ref="seo"
                                type="special"
                                :id="special.id"
                                @loading="v => loading = v"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn rounded block color="primary" type="submit" :disabled="!validSeo" :loading="loading">
                                {{ $t('base.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import VlabBaseMessageBtn from '@/shared/components/base/VlabBaseMessageBtn'
import VlabBaseSwitch from '@/shared/components/base/VlabBaseSwitch'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvBaseSlugField from '@/components/base/HdvBaseSlugField'
import HdvSaleSpecialTitle from '@/components/sale-special/HdvSaleSpecialTitle'
import HdvSaleSpecialDescription from '@/components/sale-special/HdvSaleSpecialDescription'
import HdvSaleSpecialCategory from '@/components/sale-special/HdvSaleSpecialCategory'
import HdvSaleSpecialCategories from '@/components/sale-special/HdvSaleSpecialCategories'
import HdvSaleSpecialStory from '@/components/sale-special/HdvSaleSpecialStory'
import HdvSaleSpecialIssuu from '@/components/sale-special/HdvSaleSpecialIssuu'
import HdvSaleSpecialUpload from '@/components/sale-special/HdvSaleSpecialUpload'
import HdvSaleSpecialOnline from '@/components/sale-special/HdvSaleSpecialOnline'
import HdvSaleSpecialHighlight from '@/components/sale-special-highlight/HdvSaleSpecialHighlight'
import HdvBaseImage from '@/components/base/HdvBaseImage'
import HdvSeoForm from '@/components/seo/HdvSeoForm'

export default {
    name: 'hdv-sale-special-form',
    components: {
        VlabBaseCardTitle,
        VlabBaseMessageBtn,
        VlabBaseSwitch,
        HdvBaseI18nArea,
        HdvBaseSlugField,
        HdvSaleSpecialTitle,
        HdvSaleSpecialDescription,
        HdvSaleSpecialCategory,
        HdvSaleSpecialCategories,
        HdvSaleSpecialStory,
        HdvBaseImage,
        HdvSaleSpecialIssuu,
        HdvSaleSpecialUpload,
        HdvSaleSpecialOnline,
        HdvSaleSpecialHighlight,
        HdvSeoForm
    },
    props: {
        saleId: { type: String },
        specialId: { type: String }
    },
    data: () => ({
        tab: 'form',
        loading: false,
        valid: false,
        validSeo: false,
        sale: null,
        special: null
    }),
    computed: {
        editMode() {
            return this.specialId !== 'add'
        },
        categories() {
            if (!this.sale) {
                return []
            }
            return this.sale.vacations.reduce((acc, vacation) => {
                acc.push({ header: vacation.title })
                return acc.concat(vacation.categories.map(category => ({
                    value: category.id,
                    text: category.title
                })))
            }, [])
        },
        stories() {
            return this.$store.getters['sale/stories']
        }
    },
    watch: {
        $route: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('sale/get', { id: this.saleId }),
                    this.editMode ? this.$store.dispatch('spec/get', { sale_id: this.saleId, id: this.specialId, include_highlight: true }) : null,
                    this.$store.dispatch('sale/stories', { id: this.saleId })
                ])
                .then(data => {
                    this.sale = data[0]
                    this.special = data[1] || {
                        id: null,
                        title_i18n: {},
                        slug_i18n: {},
                        description_i18n: {},
                        highlights: []
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'spec/update'
                : 'spec/add'

            return this.$store.dispatch(action, { sale_id: this.saleId, special: this.special })
                .then(async special => {
                    if (!this.editMode) {
                        this.special.id = special.id
                        await Promise.all([
                            this.$refs.upload.upload(),
                            this.$refs.uploadBanner.upload(),
                            this.$refs.uploadCover.upload(),
                            this.$refs.uploadCatalog.upload(),
                            this.$refs.uploadResult.upload()
                        ])
                    }
                    this.$success(special, { message: 'salespecial.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'salespecialform', params: { specialId: special.id } })
                    }
                })
                .catch(err => this.$err(err, null, this.$refs.form))
                .finally(() => (this.loading = false))
        },

        removeDoc(type) {
            this.loading = true
            return this.$store.dispatch('spec/removeDoc', { sale_id: this.saleId, special_id: this.specialId, type })
                .then(res => {
                    this.special[`${type}_url`] = null
                    const message = type === 'catalog' ? 'salespecial.removeCatalogDone' : 'salespecial.removeResultsDone'
                    this.$success(res, { message })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submitSeo() {
            return this.$refs.seo.submit(this.$refs.formSeo)
        }
    }
}
</script>
