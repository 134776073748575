<template>
    <v-select
        v-model="status"
        outlined
        :items="items"
        :label="$t('sale.status')">
    </v-select>
</template>

<script>

export default {
    name: 'hdv-sale-status',
    props: {
        value: { type: String }
    },
    computed: {
        status: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        items() {
            return this.$store.getters['sale/statutes'].map(status => ({
                text: this.$i18n.t(`sale.statutes.${status}`),
                value: status
            }))
        }
    }
}
</script>
