import Vue from 'vue'

// implicitement Europe/Zurich (les PC HDV sont configurés comme tels)
const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

/**
 * Transforme une date Nx implicitement Europe/Zurich en une date
 * correctement timezonée
 *
 * @param {String} date Date au format [YYYY-MM-DD HH:mm:ss]
 * @returns {Date}
 */
const getTimezonedDate = date => {
    if (!date) {
        return null
    }
    const timesep = (date.match(/:/g) || []).length
    if (timesep === 0) {
        // pas d'heure spécifiée
        return date
    }
    // ajout des secondes si nécessaire
    const suffix = timesep === 1 ? ':00Z' : 'Z'

    const timestampUTC = Date.parse(date.replace(' ', 'T') + suffix)

    return new Date(timestampUTC + timezoneOffset)
}

export default {
    namespaced: true,

    state: {
        current: null,
        nxSales: [],
        sales: [],
        stories: [],
        selected: null,
        selectedId: Vue.prototype.$storage.get('hdvsale', null)
    },

    getters: {
        current: state => state.current,
        nxSales: state => state.nxSales,
        sales: state => state.sales,
        stories: state => state.stories,
        selected: state => state.selected,
        selectedId: state => state.selectedId,
        statutes: () => [
            'disabled',
            'preview',
            'active'
        ]
    },

    mutations: {
        select(state, { id, sale }) {
            state.selectedId = id
            state.selected = sale
            Vue.prototype.$storage.set('hdvsale', id)
        },

        reset(state) {
            state.stories = []
        }
    },

    actions: {
        async sales({ state }) {
            if (state.nxSales.length) {
                return state.sales
            }
            const [nxSales, sales] = await Promise.all([
                Vue.prototype.$api.get('/nx/sales'),
                Vue.prototype.$api.get('/sales', {
                    params: {
                        limit: 200,
                        include_disabled: true,
                        include_event: true
                    }
                })
            ])
            state.nxSales = nxSales.data
            state.sales = sales.data
            return [nxSales.data, sales.data]
        },

        async get(context, { id, edit }) {
            let res, imported
            if (String(id).match(/^[0-9]+$/)) {
                res = await Vue.prototype.$api.get(`/nx/sales/${id}`)
                imported = false
            } else {
                res = await Vue.prototype.$api.get(`/sales/${id}`, {
                    meta: { lang: '*' },
                    params: {
                        edit,
                        include_vacations: true,
                        include_categories: true
                    }
                })
                imported = true
            }
            return {
                ...res.data,
                imported
            }
        },

        async import({ state }, { nxId }) {
            const resNx = await Vue.prototype.$api.get(`/nx/sales/${nxId}`, {
                params: {
                    include_vacation_disabled: true
                }
            })
            const nxSale = resNx.data

            const params = {
                ...nxSale,
                consignation_date_end: nxSale.consignationDate,
                restitution_date_end: getTimezonedDate(nxSale.restitutionDateLastcall),
                restitution_text_i18n: nxSale.restitutionDates,
                restitution_dates: nxSale.restitutionDatesData,
                title_i18n: { fr: nxSale.title },
                nx_sale_id: nxSale.id
            }
            const res = await Vue.prototype.$api.post(`/nx/sales/${nxId}`, params)
            const index = state.sales.findIndex(sale => sale.id === res.data.id)
            if (index === -1) {
                state.sales.push(res.data)
            }

            // récupération des vacations existantes
            const { data: vacations } = await Vue.prototype.$api.get(`/sales/${res.data.id}/vacations`, {
                params: {
                    include_disabled: true
                }
            })

            // envoi de toutes les vacations

            await Promise.all(nxSale.vacations.map(nxVacation => {
                if (nxVacation.disabled) {
                    const v = vacations.find(vac => vac.nx_id === nxVacation.id)
                    return v
                        ? Vue.prototype.$api.delete(`/sales/${res.data.id}/vacations/${v.id}`)
                        : Promise.resolve()
                }
                return Vue.prototype.$api.post(`/nx/sales/${nxId}/vacations`, {
                    nx_id: nxVacation.id,
                    title: nxVacation.title,
                    title_i18n: nxVacation.title_i18n,
                    slug: nxVacation.alias,
                    slug_i18n: nxVacation.alias_i18n,
                    date_start: getTimezonedDate(nxVacation.date_start),
                    date_end: getTimezonedDate(nxVacation.date_end),
                    online: nxVacation.silent,
                    disabled: nxVacation.disabled,
                    am_disabled: nxVacation.am_disabled,

                    categories: nxVacation.categories.map(nxCategory => ({
                        id: nxCategory.id,
                        nx_id: nxCategory.id,
                        title: nxCategory.title?.fr,
                        title_i18n: nxCategory.title, // i18n dans nx sous cette clé
                        slug: nxCategory.alias?.fr,
                        slug_i18n: nxCategory.alias, // i18n dans nx sous cette clé
                        specialist_id: nxCategory.specialist_id
                    }))
                })
            }))

            return res.data
        },

        async importStories({ state }, { nxId }) {
            state.stories = []

            const res = await Vue.prototype.$api.get(`/nx/sales/${nxId}/stories`)

            return Promise.all(res.data.map(story => {
                let type = 'normal'
                if (story.important) {
                    type = 'important'
                } else if (story.essential) {
                    type = 'essential'
                } else if (story.origin) {
                    type = 'origin'
                }

                return Vue.prototype.$api.post(`/nx/sales/${nxId}/stories`, {
                    nx_id: story.nx_id,
                    title: story.title,
                    title_i18n: story.title_i18n,
                    description: story.content,
                    description_i18n: story.content_i18n,
                    type
                })
            }))
        },

        async stories({ state }, { id }) {
            if (state.stories.length) {
                return state.stories
            }
            const res = await Vue.prototype.$api.get(`/sales/${id}/stories`, {
                params: {
                    include_translations: true
                }
            })
            state.stories = res.data
            return res.data
        },

        async update(context, { id, sale }) {
            return Vue.prototype.$api.put(`/sales/${id}`, sale)
        },

        async getAm(context, { id }) {
            const res = await Vue.prototype.$api.get(`/sales/${id}/am`)
            return res.data
        },

        async updateAm(context, { saleId, am }) {
            const amData = am.map(a => ({
                vacation_id: a.id,
                am_id: a.am_id
            }))
            return Vue.prototype.$api.put(`/sales/${saleId}/am`, { am: amData })
        },

        async getCatalogs(context, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/catalogs`)
            return res.data
        },

        async updateCatalogs(context, { saleId, catalogs }) {
            // les catalogues sont en fait une partie des special sales
            return Promise.all(catalogs.map(catalog => {
                return Vue.prototype.$api.put(`/sales/${saleId}/catalogs/${catalog.id}`, {
                    issuu_url: catalog.issuu_url
                })
            }))
        },

        async getItems(context, { nxSaleId, stockNo }) {
            const res = await Vue.prototype.$api.get(`/nx/sales/${nxSaleId}/items`, {
                params: {
                    stock_no: stockNo || ''
                }
            })
            return res.data
        },

        async setVacationHighlight(context, { saleId, vacationId, lotId }) {
            if (lotId) {
                await Vue.prototype.$api.post(`/sales/${saleId}/vacations/${vacationId}/highlight`, {
                    lot_id: lotId
                })
            } else {
                await Vue.prototype.$api.delete(`/sales/${saleId}/vacations/${vacationId}/highlight`)
            }
        },

        removeDoc(context, data) {
            return Vue.prototype.$api.delete(`/sales/${data.sale_id}/${data.type}`)
        },

        async getCurrentSale({ state }) {
            if (state.current) {
                return state.current
            }
            const res = await Vue.prototype.$api.get('/sales-current')
            state.current = res.data
            return state.current
        },

        async setCurrentSale({ state }, { saleId }) {
            await Vue.prototype.$api.post(`/sales/${saleId}/current`)

            const res = await Vue.prototype.$api.get(`/sales/${saleId}`)
            const sale = res.data

            state.current = {
                id: sale.id,
                title: sale.title
            }
        },

        async listVacations(context, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/vacations`)
            return res.data
        },

        async getVacation(context, { saleId, vacationId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/vacations/${vacationId}`)

            return res.data
        },

        async saveVacation(context, { saleId, vacationId, vacation }) {
            await Vue.prototype.$api.put(`/sales/${saleId}/vacations/${vacationId}`, vacation)
        }
    }
}
